<template>
  <div id="app">
    <GarbageSearch />
  </div>
</template>

<script>
import GarbageSearch from './components/GarbageSearch.vue';

export default {
  components: {
    GarbageSearch,
  },
};
</script>